<template>
  <div class="intranet-container">
    <header class="px-0" height="200px">
      <div class="w-full flex justify-center items-center py-4 bg-gradient-to-b from-blue-20 to-blue-10">
        <div class="w-full flex sm:justify-start items-end gap-2 ml-4">
          <img class="img" src="../assets/logotipo.png" alt="Logo PMDF">
          <div class="text-white">
            <h1 class="hidden sm:block md:text-2xl text-lg leading-tight font-bold uppercase">departamento de controle e correição</h1>
            <h2 class="hidden sm:block md:text-lg text-base leading-tight">Plataforma DAT/STIC</h2>
          </div>
        </div>
      </div>
      <div class="flex justify-center sm:h-14 bg-blue-30 text-white p-2">
        <div class="w-full flex sm:justify-between justify-end ">
          <div class="flex flex-col">
            <h1 class="sm:hidden leading-tight text-base font-bold uppercase">departamento de controle e correição</h1>
            <h1 class="sm:hidden leading-tight text-sm">Plataforma DAT/STIC</h1>
          </div>
          <div class="flex justify-end w-full">
            <div v-if="user" class="flex items-center gap-2 mr-2">
              <div class="w-7 h-7 flex justify-center items-center bg-blue-900 rounded-full uppercase">
                <i class="ri-user-settings-line text-blue-500"></i>
              </div>
              <span v-if="user.user" class="text-sm">{{ `${user.user.title} ${user.user.nome_guerra}` }}</span>
              <el-dropdown trigger="click" size="large">
                <i class="text-white ri-more-2-fill"></i>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item>
                      <el-button @click="closeSystem" type="danger" plain>
                        Sair do sistema
                      </el-button>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </header>
    <el-container class="intranet__layout">
      <el-aside class="aside-container" >
        <Sidebar :perfilUser="perfilUser" />
      </el-aside>
      <main class="bg-gray-100">
        <router-view :perfilUser="perfilUser" ></router-view>
      </main>
    </el-container>
  </div>  
</template>

<script>
  import { mapState } from 'vuex'
  import Sidebar from '@/components/intranet/Sidebar.vue'
  import { jwtVerify } from '@/utils/jwt.js'
  export default {
    name: 'IntranetLayout',
    components: {
      Sidebar
    },
    data() {
      return {
        perfilUser: []
      }
    },
    computed: {
      ...mapState({
        user: (state) => state.userLogged 
      })
    },
    methods: {
      closeSystem() {
        localStorage.removeItem('av_token')
        localStorage.removeItem('user_session')
        this.$router.push({ path: '/' })
      }
    },
    created() {
      const { rolesUser } = jwtVerify(this.user.user.token_session, process.env.VUE_APP_KEY)
      if(rolesUser) this.perfilUser = rolesUser
    }
  }
</script>

<style scoped>
.img {
  @apply w-16 lg:w-20 h-20 lg:h-24;
}
.intranet-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.intranet__layout {
  display: grid;
  grid-template-columns: 150px 1fr;
}

.intranet__layout main {
  width: 100%;
}
.aside-container {
  width: 100%;
  background: #fff;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1200px) {
  .intranet-container {
    overflow: auto;
  }
}

</style>