<template>
  <div>
    <el-menu
      @select="setPath"
      :default-active="actived"
      :collapse="false"
      text-color="#9ca3af"
    >
      <el-menu-item v-if="accessLevel === 'MANAGER'" index="dashboard">
        <i class="ri-dashboard-line mr-2"></i>
        <template #title>DashBoard</template>
      </el-menu-item>
      <el-sub-menu index="2">
        <template #title>
          <i class="ri-group-line mr-2"></i>
          <span>Usuários</span>
        </template>
        <el-menu-item index="userSearch">
          <i class="ri-search-eye-line mr-2"></i>
          <template #title>Buscar</template>
        </el-menu-item>
        <el-menu-item v-if="accessLevel === 'MANAGER'" index="modal">
          <i class="ri-user-add-line mr-2"></i>
          <template #title>Criar</template>
        </el-menu-item>
      </el-sub-menu>
      <el-menu-item index="about">
        <i class="ri-article-line mr-2"></i>
        <template #title>Sobre</template>
      </el-menu-item>
    </el-menu>
  </div>
  <ModalCreateUser
    @close="modalToggle = false"
    :visible="modalToggle"
    :accessUser="{accessLevel, roles: perfilUser, parent: 'Sidebar'}"
  />
</template>

<script>
import ModalCreateUser from '@/components/intranet/ModalCreateUser.vue'
import { permissionAccess } from '@/utils/geral'

export default {
  name: 'Sidebar',
  components: {
    ModalCreateUser
  },
  props: ['perfilUser'],
  data() {
    return {
      actived: 'dashboard',
      menuItens: {
        'dashboard': '/app.dcc/dashboard',
        'userSearch': '/app.dcc/list',
        'modal': 'modal',
        'about': '/app.dcc/about',
      },
      modalToggle: false
    }
  },
  computed: {
    accessLevel() {
      return permissionAccess(this.perfilUser)
    }
  },
  mounted() {
    const { path } = this.$route
    if (path === '/app.dcc/dashboard') this.actived = 'dashboard'
    if (path === '/app.dcc/list') this.actived = 'userSearch'
  },
  methods: {
    setImport(path) {
      return () => import(path)
    },
    setPath(item) {
      if (item === 'modal') {
        this.modalToggle = true
        return
      }
      this.$router.push({
        path: this.menuItens[item]
      })
    }
  }
}
</script>
